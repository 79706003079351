var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "사고방지대책 및 원인분석" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "설비", value: _vm.accData.equip },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "관리", value: _vm.accData.management },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "교육", value: _vm.accData.education },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "환경", value: _vm.accData.enviroment },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "개인", value: _vm.accData.individual },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "기타", value: _vm.accData.etc },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-6 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고원인분석",
                    value: _vm.accData.shortPlan,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-6 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: { title: "개선대책", value: _vm.accData.longPlan },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [_c("c-upload", { attrs: { editable: false } })],
              1
            ),
          ]),
        ],
        2
      ),
      _c("br"),
      _c("c-table", {
        ref: "tableImpr",
        attrs: {
          title: "개선 목록",
          columns: _vm.gridImpr.columns,
          gridHeight: _vm.gridImpr.height,
          data: _vm.accData.accidentImproveModelList,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: false,
                          isImmShow: true,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "causesNo",
                          ibmTaskTypeCd: "ITT0000040",
                          ibmTaskUnderTypeCd: "ITTU000065",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }